import React, { Component } from "react";
import { Link } from "react-router-dom";


class AppNavbar extends Component {

  state = {
    user: {},
    isAuthenticated: false
  };

  static getDerivedStateFromProps(props) {
    const { isAuthenticated } = props;
    if (isAuthenticated) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.parentClearUserData();
  };


  render() {
    const { isAuthenticated } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
        <div className="container">
          <Link to="/" className="navbar-brand">
            الأنشطة والفعاليات
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarMain">
            {isAuthenticated ? (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a href="#!" className="nav-link">
                    {this.props.email}
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    الملف الشخصي
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="#!"
                    className="nav-link"
                    onClick={this.onLogoutClick}
                  >
                    تسجيل الخروج
                  </a>
                </li>
              </ul>
            ) : null}
            {!isAuthenticated ? (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to="/login" className="nav-link">
                    تسجل الدخول
                  </Link>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </nav>
    );
  }
}
export default AppNavbar;