import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Alert = props => {
  const { message, messageType } = props;
  return (
    <div
      className={classnames('alert', {
        'alert-success': messageType === 'success',
        'alert-danger': messageType === 'error'
      })}
    >
      {message}
      {<button type="button" className="close" data-dismiss="alert" aria-label="Close"><FaTimesCircle /></button>}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired
};

export default Alert;
