
import feathers from '@feathersjs/client'


class Feathers {

	constructor() {
		this.client = feathers().configure(feathers.rest('https://apis.abegs.org').fetch(window.fetch))
			.configure(feathers.authentication({ service: 'users', storage: localStorage }));
	}

	service(servicename) {
		return this.client.service(servicename);
	}

	getClient() {
		return this.client;
	}

	auth(obj) {
		return this.client.authenticate(obj)
	}

	async loginUser(loginData) {
		try {
			const response = await this.client.authenticate({ ...loginData });
			const payload = await this.client.passport.verifyJWT(response.accessToken);
			const user = await this.client.service('users').get(payload.userId);
			if (user && user.id) {
				this.client.set('user', user);
			}
			else {
				this.client.set('user', null);
			}
		}
		catch (err) {
			console.log(err);
		}
	}

	async logout() {
		await this.client.logout();
		this.client.set('user', null);
	}

}

export default new Feathers()
