import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from "./layout/Spinner";
import Feathers from '../Feathers';
import Moment from 'moment';

class DisplayEvents extends Component {
    state = {
        hasMore: true,
        events: [],
        start: 0
    };

    async componentDidMount() {
        let events = await Feathers.service("events").find({ query: { $limit: 10, start: this.state.start, $select: ['title', 'image_url', 'description', 'start_date', 'end_date', 'created_by'], include: [{ 'tbl': 'auth_user', 'key': 'created_by', 'recordfield': 'username', 'tblfield': 'first_name' }], $sort: [{ fld: 'created_on', desc: 'true' }] } });
        if (events.length > 0) {
            this.setState({ events: events, start: this.state.start + 10 });
        }
        else {
            this.setState({ start: 0 });
            let events = await Feathers.service("events").find({ query: { $limit: 10, start: this.state.start, $select: ['title', 'image_url', 'description', 'start_date', 'end_date', 'created_by'], include: [{ 'tbl': 'auth_user', 'key': 'created_by', 'recordfield': 'username', 'tblfield': 'first_name' }], $sort: [{ fld: 'created_on', desc: 'true' }] } });
            if (events.length > 0) {
                this.setState({ events: events, start: this.state.start + 10 });
            }
        }
    }

    fetchMoreData = async () => {
        let events = await Feathers.service("events").find({ query: { $limit: 10, start: this.state.start, $select: ['title', 'image_url', 'description', 'start_date', 'end_date', 'created_by'], include: [{ 'tbl': 'auth_user', 'key': 'created_by', 'recordfield': 'username', 'tblfield': 'first_name' }], $sort: [{ fld: 'created_on', desc: 'true' }] } });
        if (events.length === 0) {
            this.setState({ hasMore: false });
            return
        }
        setTimeout(() => {
            this.setState(state => ({
                events: [
                    ...state.events,
                    ...events
                ],
                start: this.state.start + 10
            }));
        }, 1500);
    };

    render() {
        Moment.locale('en');
        const { events } = this.state;
        if (events) {
            return (
                <div className="container">
                    <InfiniteScroll dataLength={this.state.events.length} next={this.fetchMoreData} hasMore={this.state.hasMore} loader={<Spinner />} endMessage={<p style={{ textAlign: 'center' }}><b>تم عرض كل البيانات</b></p>}>
                        <div className="row">
                            {events.map(event => (
                                <div className="col-12" key={event.id}>
                                    <div className="card mt-1 mb-1">
                                        <img className="card-img-top" src={event.image_url} alt={event.title} />
                                        <div className="card-body">
                                            <h5 className="card-title"> {event.title} </h5>
                                            <p className="card-text text-justify">{event.description}</p>
                                        </div>
                                        <div class="card-footer">
                                            <small class="text-muted">
                                                {Moment(event.start_date).format('YYYY/MM/DD')} -
                                            {Moment(event.end_date).format('YYYY/MM/DD')}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}

export default DisplayEvents;
