import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppNavbar from './components/layout/AppNavbar';
import { createBrowserHistory } from "history";
import Feathers from './Feathers';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import DisplayEvents from './components/DisplayEvents';
const history = createBrowserHistory();

class App extends Component {

  state = { user: null, isAuthenticated: false };

  parentSetUserData = (user) => {
    let isAuthenticated = user.id ? true : false;
    this.setState({ user: user, isAuthenticated });
  }

  parentClearUserData = () => {
    this.setState({ user: null, isAuthenticated: false });
    Feathers.logout();
  }

  render() {
    return (
      <Router>
        <div className="App">
          <AppNavbar history={history} user={this.state.user} isAuthenticated={this.state.isAuthenticated} expertid={this.state.expertid} parentClearUserData={this.parentClearUserData} />
          <div className="container">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <DisplayEvents {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />} />
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />} />
              <Route
                exact
                path="/register"
                render={(props) => <Register {...props} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
