import React, { Component } from 'react'
import Feathers from '../Feathers';
import Alert from './layout/Alert';
import { Link } from "react-router-dom";
import { FaUserAlt, FaHome} from 'react-icons/fa';

class Register extends Component {

    state = { message: null, messageType: null, first_name: '', last_name: '', username: '', email: '', password: '', mobile:'', roles: 'users'};

    setHandleChang = (e) => {
        this.setState({ [e.target.id]: e.target.value })

    }

    register = async e => {
        e.preventDefault();
        let { first_name, last_name, username, email, password, mobile, roles } = this.state;
        let myReg = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
        if (first_name.trim() !== '' && last_name.trim() !== '' && username.trim() !== '' && email.trim() !== '' && password.trim() !== '' && password.trim().length > 5 && myReg.test(email)) {
            try {
                let userrec = {first_name: first_name, last_name:last_name, username:username,  email: email, password: password, mobile:mobile, roles:roles}
                let user = await Feathers.service("users").create(userrec);
                if (user.id) {
                    this.props.history.replace('/');
                }
                else {
                    this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى" });
                }
            }
            catch (err) {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى" + err.message });
            }
        } else {
            this.setState({ message: "الرجاء التأكد من المدخلات ..... " });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            {this.state.message ? (
                                <Alert message={this.state.message} messageType="error" />) : null}
                            <h4 className="text-center pb-4 pt-3">
                                <span className="text-primary">
                                    مستخدم جديد </span>
                            </h4>
                            <form onSubmit={e => e.preventDefault() && false}>
                            <div className="form-group">
                                    <label htmlFor="first_name">الاسم الاول:</label>
                                    <input type="first_name" className="form-control" id="first_name" autoComplete="off" autoFocus value={this.state.first_name} onChange={this.setHandleChang} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="last_name">الاسم الثاني:</label>
                                    <input type="last_name" className="form-control" id="last_name" autoComplete="off" autoFocus value={this.state.last_name} onChange={this.setHandleChang} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username">اسم المستخدم:</label>
                                    <input type="username" className="form-control" id="username" autoComplete="off" autoFocus value={this.state.username} onChange={this.setHandleChang} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">البريد الإلكتروني:</label>
                                    <input type="email" className="form-control" id="email" autoComplete="off" autoFocus value={this.state.email} onChange={this.setHandleChang} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">كلمة المرور:</label>
                                    <input className="form-control" type="password" id="password" autoComplete="off" value={this.state.password} onChange={this.setHandleChang} />
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={this.register}> <FaUserAlt /> تسجيل </button>
                                {' '}
                                <Link to="/" className="btn btn-primary m-2"> إلغاء الأمر <FaHome /></Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Register;
